<template>
  <div style="height: 100vh;" class="overflow-hidden">
    <!-- Inicio Modal Add -->
    <v-dialog v-model="modal.add" width="900">
      <v-card class="overflow-x-hidden">
        <div class="d-flex justify-center"><v-spacer></v-spacer>
          <v-card-title>Adicionar Doca:</v-card-title><v-spacer></v-spacer>
          <v-btn icon @click="modal.add = false">
            <v-icon color="error">fas fa-times</v-icon>
          </v-btn>
        </div>
        <v-form ref="form" @submit.prevent="createdDoca">
          <v-row class="justify-center">
            <v-col cols="10" class="pb-0">
              <v-text-field type="text" :rules="rulesChar" outlined label="Nome" v-model="newDoca.nome"></v-text-field>
            </v-col>
            <v-col cols="10" class="py-0">
              <v-text-field type="text" :rules="rulesChar" outlined label="Token" v-model="newDoca.token"></v-text-field>
            </v-col>
            <v-col cols="10" class="pt-0">
              <v-text-field type="text" :rules="rulesChar" outlined label="Imagem"
                v-model="newDoca.imagem"></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-2 justify-end">
            <v-btn @click="modal.add = false" color="#F44336" style="color: white;">Cancelar</v-btn>
            <v-btn type="submit" class="ml-2" dense color="#018656" style="color: white;">Salvar</v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Fim Modal Add -->

    <!-- Inicio Modal Doca -->
    <v-dialog v-model="modal.doca" fullscreen :scrim="false" transition="dialog-bottom-transition">
      <v-card class="overflow-x-hidden">
        <v-toolbar dark style="background-color: rgb(1, 134, 86);">
          <v-btn icon>
            <v-icon>fas fa-warehouse</v-icon>
          </v-btn>
          <v-toolbar-title>Docas ({{ docaNome }})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="modal.doca = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="3" class="pl-7 pb-1" style="display: inline-flex; justify-content: start;">
            <v-icon>fas fa-warehouse</v-icon>
            <v-card-title>{{ docaNome }}</v-card-title>
          </v-col>
          <v-col cols="4" class="pb-1" style="display: flex; align-items: end;">
            <v-card-text class="pl-0"><b>Token: {{ docaToken }}</b></v-card-text>
          </v-col>
          <v-col cols="3" class="pb-1" style="display: flex; align-items: end;">
            <v-card-text><b>Cidade Origem:</b></v-card-text>
          </v-col>
          <v-col cols="2" class="d-flex justify-end pb-1" style="padding-right: 40px; padding-top: 20px;">
            <v-btn icon style="height: 50px; width: 50px;" @click="montaConfig()">
              <v-icon style="font-size: 25px;">fas fa-cog</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="py-0"></v-divider>
        <div class="d-flex pl-4 pt-0 pb-0">
          <v-icon class="pt-0">fas fa-shipping-fast</v-icon>
          <v-card-title class="pt-4">Transportadoras Vinculadas:</v-card-title>
        </div>

        <template v-if="grid.transportadoras.carregando">
          <v-row style="height: 100%;" dense align="start" justify="center">
            <v-progress-circular class="mt-4" indeterminate color="#018656"></v-progress-circular>
          </v-row>
        </template>

        <template v-else>
          <div class="pt-0" v-for="cd in cds" v-if="cd.nome == nomeCd">
            <v-row v-for="doca in cd.docas" v-if="doca.nome == docaNome" class="ma-2 mt-0 d-flex flex-wrap">
              <div v-for="(transp, index) in doca.transportadoras" v-if="index >= start - 1 && index < end"
                class="widthCard pb-5 pt-0">
                <v-card class="cardSelectedTransp pb-0" style="cursor: pointer;">
                  <div style="height: 10vh; display: grid; align-items: center; width: 95%;
									justify-content: center;" @click="openTransp(transp)">
                    <!-- <v-img contain style="width: 80%; position: relative; left: 20px;"
											:src="transp.imagem"></v-img> -->
                    <v-card-text style="font-weight: bolder; font-size: 12px;" class="text-center">
                      {{ transp.transportadora.split(" ")[0] }} {{ transp.transportadora.split(" ")[1] }}
                      {{ transp.transportadora.split(" ")[2] }}</v-card-text>
                  </div>
                  <v-divider></v-divider>
                  <v-card-text @click="modal.transp = true, switchModais = [], transpNome = transp.nome" class="py-0 pl-1"
                    style="font-weight: bolder; color: black;">
                    Origem:
                  </v-card-text>
                  <v-card-text @click="modal.transp = true, switchModais = [], transpNome = transp.nome" class="py-0 pl-1"
                    style="font-weight: bolder; color: black;">
                    Serviço: <b>{{ transp.servico }}</b>
                  </v-card-text>
                  <div class="d-flex justify-space-around py-0">
                    <v-switch multiple height="0" dense v-model="doca.transpSelect" :value="transp.transportadora"
                      class="py-0"></v-switch>
                    <v-switch multiple height="0" dense v-model="transp.cotacaoWeb" class="py-0"></v-switch>
                  </div>
                  <v-row style="height: 2em;" @click="modal.transp = true, switchModais = [], transpNome = transp.nome"
                    class="pb-0 px-2 pt-1 justify-space-around">
                    <p class="py-0 pl-2" style="font-size: 14px;">Ativo</p>
                    <p class="py-0" style="font-size: 14px;">Cotação Web</p>
                  </v-row>
                </v-card>
              </div>
            </v-row>
          </div>
          <div class="text-center pb-7">
            <v-pagination @input="paginacaoTransps(page)" v-model="page" :length="grid.transportadoras.size"
              :total-visible="5"></v-pagination>
          </div>
        </template>
      </v-card>
    </v-dialog>
    <!-- Fim Modal Doca -->

    <!-- Inicio Modal Transp -->
    <v-dialog v-model="modal.transp" max-width="1000">
      <v-card class="overflow-hidden">
        <div class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-card-title>Configurações Gerais da transportadora {{ transpNome }}</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="modal.transp = false">
            <v-icon color="error">fas fa-times</v-icon>
          </v-btn>
        </div>
        <v-divider></v-divider>

        <v-row class="mr-4 pb-0 mt-1 justify-end">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="openReplic()" v-on="on">
                <v-icon>fas fa-copy</v-icon>
              </v-btn>
            </template>
            <span>Replicar</span>
          </v-tooltip>
          <v-btn icon>
            <v-icon>fas fa-lock</v-icon>
          </v-btn>
        </v-row>

        <v-form ref="form">
          <v-row class="px-5 justify-center pb-0">
            <v-col cols="3">
              <v-text-field label="Nome Transportadora" type="text"
                v-model="editedItem.transportadora.nome"></v-text-field>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="3">
              <v-text-field label="Serviço" type="text" v-model="editedItem.transportadora.servico"></v-text-field>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="3">
              <v-text-field label="CNPJ" type="number" v-model="editedItem.transportadora.cnpj"></v-text-field>
            </v-col>
          </v-row>
          <v-row style="justify-content: space-evenly;">
            <v-col cols="2" class="py-0"></v-col>
            <v-col cols="3" class="py-0">
              <v-text-field outlined type="number" label="Acréscimo Frete(%)"
                v-model="editedItem.transportadora.acresFrete"></v-text-field>
            </v-col>
            <v-col cols="2" class="py-0"></v-col>
            <v-col cols="3" class="py-0">
              <v-text-field label="Prazo Adicional" type="number" outlined
                v-model="editedItem.transportadora.prazoAdc"></v-text-field>
            </v-col>
            <v-col cols="2" class="py-0"></v-col>
            <v-col cols="2" class="py-0 ml-12 colOption">
              <v-switch v-model="editedItem.transportadora.cotacaoWeb" label="Cotação Web" inset></v-switch>
            </v-col>
            <v-col cols="2" class="py-0 colOption">
              <v-switch label="Prioridade Registro" v-model="editedItem.transportadora.prioriRegistro" inset></v-switch>
            </v-col>
            <v-col cols="2" class="py-0 mt-2 colOption" style="display: flex; justify-content: center;">
              <v-switch v-model="editedItem.transportadora.redespacho" label="Redespacho" inset></v-switch>
            </v-col>
            <v-col cols="2" class="py-0 mr-4" style="display: flex; justify-content: center;">
              <v-switch style="display: flex; align-items: center;" v-model="editedItem.transportadora.sempreVisivel"
                label="Sempre visível" inset></v-switch>
            </v-col>
          </v-row>
          <v-row class="pt-0 px-14">
            <fieldset style="width: 100%; border-radius: 10px;">
              <legend style="padding-inline: 6px; font-weight: bolder;" class="ml-4">Modais</legend>
              <v-row class="ma-1 justify-start">
                <v-col class="py-0" v-for="modal in modais" cols="2">
                  <v-switch multiple v-model="switchModais" class="pt-0 d-inline-flex" :label="modal"
                    :value="modal"></v-switch>
                </v-col>
              </v-row>
            </fieldset>
          </v-row>

          <v-row class="ma-4 justify-end pt-4">
            <v-btn class="mr-2" dense color="#F44336" style="color: white;" @click="modal.transp = false">Cancelar</v-btn>
            <v-btn dense color="#018656" style="color: white;" @click="saveTransp()">Salvar</v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Fim Modal Transp -->

    <!-- Inicio Modal Replic -->
    <v-dialog v-model="modal.replic" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark style="background-color: rgb(1, 134, 86);">
          <v-btn icon>
            <v-icon>fas fa-cog</v-icon>
          </v-btn>
          <v-toolbar-title>Replicação de Configurações ({{ docaNome }} - {{ nomeCd }})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="modal.replic = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="mx-auto" style="overflow-y: auto;">
          <div style="z-index: 204; background: #ebebeb; width: 100%; display: flex;">
            <v-card-title class="justify-space-between" style="font-size: 16px; font-weight: 800;">
              <span>Selecione os itens que deseja replicar:</span>
            </v-card-title><v-spacer></v-spacer>
            <v-switch @click="selectItems(selectAll)" v-model="selectAll" style="margin-right: 20px;"
              label="Selecionar Todos" value="1"></v-switch>
          </div>

          <v-window v-model="step" style="overflow: hidden;">
            <v-row class="justify-space-evenly ma-2">
              <v-container class="justify-space-evenly">
                <v-checkbox v-for="option in reOptions"
                  @click="btnReplic = false, allTranspBtn = false, transpBtn = false, btnAutomat = false"
                  v-model="selectedOption" :label="option" :value="option"></v-checkbox>
              </v-container>
            </v-row>
          </v-window>
          <v-row v-if="step != 2 && this.selectedOption.length > 0" class="justify-end ma-2 mt-0 mr-1">
            <v-btn style="color: white;" color="#018656" @click="step++">Avançar</v-btn>
          </v-row>
          <div v-if="step == 2">
            <v-divider></v-divider>
            <v-row class="ma-2 py-0">
              <v-col cols="4" class="d-flex py-0">
                <v-card-title class="pl-0">Selecione uma opção de replicação:</v-card-title>
              </v-col>
              <v-col cols="4" class="d-flex justify-center mt-2">
                <v-btn color="success" @click="automat(), btnAutomat = !btnAutomat" class="mr-4">Automático</v-btn>
                <v-btn color="primary" @click="manual(), montaTransps()">Manual</v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <div v-if="btnAutomat == true && selectedOption.length > 0">
              <v-row class="ma-4 justify-space-around">
                <v-btn
                  v-if="(!selectedOption.find((element) => element == 'Nome') && !selectedOption.find((element) => element == 'Serviço') && !selectedOption.find((element) => element == 'CNPJ'))"
                  @click="allTranspBtn = true, transpBtn = false, btnReplic = false, radioOption = []" size="large"
                  :color="allTranspBtn ? 'success' : ''">Todas Transportadoras</v-btn>
                <v-btn @click="transpBtn = true, allTranspBtn = false, btnReplic = false, radioOption = []" size="large"
                  :color="transpBtn ? 'success' : ''">Transportadora {{ transpNome }}</v-btn>
              </v-row>

              <v-divider></v-divider>
              <div class="pt-1" v-if="allTranspBtn == true || transpBtn == true">
                <v-row v-if="allTranspBtn == true" class="ma-4 justify-space-around">
                  <v-btn
                    @click="btnReplic = true, radioOption = ['option01'], modal.resumo = true, tbRes = 0, montaListas()"
                    size="large" :color="radioOption.includes('option01') ? 'success' : ''">Doca Atual</v-btn>
                  <v-btn
                    @click="btnReplic = true, radioOption = ['option02'], modal.resumo = true, tbRes = 0, montaListas()"
                    size="large" :color="radioOption.includes('option02') ? 'success' : ''">Todas as
                    Docas</v-btn>
                  <v-btn
                    @click="btnReplic = true, radioOption = ['option03'], modal.resumo = true, tbRes = 0, montaListas()"
                    size="large" :color="radioOption.includes('option03') ? 'success' : ''">Todos os Canais de Venda</v-btn>
                  <v-btn
                    @click="btnReplic = true, radioOption = ['option04'], modal.resumo = true, tbRes = 0, montaListas()"
                    size="large" :color="radioOption.includes('option04') ? 'success' : ''">Todos os Canais de Venda e Docas</v-btn>
                </v-row>
                <v-row v-if="transpBtn == true" class="ma-4 justify-space-around">
                  <v-btn
                    @click="btnReplic = true, radioOption = ['option05'], modal.resumo = true, tbRes = 0, montaListas()"
                    size="large" :color="radioOption.includes('option05') ? 'success' : ''">Todas as Docas</v-btn>
                  <v-btn
                    @click="btnReplic = true, radioOption = ['option06'], modal.resumo = true, tbRes = 0, montaListas()"
                    size="large" :color="radioOption.includes('option06') ? 'success' : ''">Todos os Canais de Venda</v-btn>
                  <v-btn
                    @click="btnReplic = true, radioOption = ['option07'], modal.resumo = true, tbRes = 0, montaListas()"
                    size="large" :color="radioOption.includes('option07') ? 'success' : ''">Todos os Canais de Venda e Docas</v-btn>
                </v-row>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- Fim Modal Replic -->

    <!-- Inicio Replic Manual -->
    <v-dialog v-model="modal.manual" fullscreen :scrim="false" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark style="background-color: rgb(1, 134, 86);">
          <v-btn icon>
            <v-icon>fas fa-cog</v-icon>
          </v-btn>
          <v-toolbar-title>Replicar Configurações - Manualmente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="selectionTransp.length > 0 || listTransp.length == 1" @click="modal.resumo = true, montaListas()"
            color="primary" class="mr-2">
            Avançar
          </v-btn>
          <v-btn icon @click="modal.manual = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="mx-auto overflow-x-hidden">
          <div style="z-index: 204; background: #ebebeb; width: 100%; height: 60px; display: flex; align-items: center;">
            <v-card-title v-if="stepReplicManual == 1" class="justify-space-between"
              style="font-size: 16px; font-weight: 800;">
              <span>Selecione as transportadoras:</span>
            </v-card-title>
            <v-card-title v-if="stepReplicManual == 2" class="justify-space-between"
              style="font-size: 16px; font-weight: 800;">
              Selecione os Canais/Docas(Transportadora {{ listTransp[0].nome }}):
            </v-card-title>
            <v-spacer></v-spacer>
            <v-switch v-if="stepReplicManual == 2" v-model="selectManual" label="Selecionar Todos"
              @click="selectAllManual(selectManual)" class="mr-4"></v-switch>
          </div>

          <div v-if="stepReplicManual == 1">
            <v-item-group multiple v-model="selectionTransp">
              <v-row class="ma-2" dense>
                <v-col v-for="transp in listTransp" cols="6" sm="3" md="2">
                  <v-item v-slot="{ active, toggle }" :value="transp.nome">
                    <v-card style="background-color: #ebebeb; cursor: pointer;">
                      <v-img @click="toggle" :src="transp.img" contain height="110" width="80%" class="text-right pt-1"
                        style="position: relative; left: 11%;">
                        <v-btn style="background-color: #018656; height: 24px; width: 24px;" v-if="active" icon>
                          <v-icon color="white" style="font-size: 16px;">fas fa-check</v-icon>
                        </v-btn>
                        <v-card-text style="font-weight: bolder;" class="text-center">{{ transp.nome }}</v-card-text>
                      </v-img>
                      <v-expansion-panel v-if="active">
                        <v-card class="pt-0">
                          <v-list class="pt-0" open-strategy="multiple" dense>
                            <v-list-group v-for="cd in transp.cds" style="border-top: 1px solid #ebebeb;" dense>
                              <template @click="toggle" v-slot:activator>
                                <span class="ml-0 titleList"
                                  style="font-size: 12px; color: rgba(0, 0, 0, 0.6); font-weight: bolder;">{{ cd.nome }}</span>
                                <v-spacer></v-spacer>
                              </template>

                              <v-list-group
                                v-if="radioOption != 'option03' && radioOption != 'option06' && cd.docas.length > 0"
                                style="border-top: 1px solid #ebebeb;" class="activeGroup" dense sub-group :value="false">
                                <template v-slot:activator>
                                  <v-list-item-title style="position: relative; right: 30%;">Docas</v-list-item-title>
                                </template>

                                <div>
                                  <v-item-group multiple v-model="cd.docasSelected">
                                    <div v-for="doca in cd.docas">
                                      <v-item v-slot="{ active, toggle }" :value="doca">
                                        <div @click="toggle">
                                          <v-list-item :style="active ? 'background-color: #1976d2;' : ''" class="py-0">
                                            <v-list-item-title :style="active ? 'color: white;' : ''">{{ doca }}</v-list-item-title>
                                          </v-list-item>
                                        </div>
                                      </v-item>
                                    </div>
                                  </v-item-group>
                                </div>
                              </v-list-group>
                              <v-list-group
                                v-if="radioOption != 'option01' && radioOption != 'option02' && radioOption != 'option05' && cd.canais.length > 0"
                                style="border-top: 1px solid #ebebeb;" class="activeGroup" dense sub-group :value="false">
                                <template v-slot:activator>
                                  <v-list-item-title style="position: relative; right: 30%;">Canais</v-list-item-title>
                                </template>

                                <v-item-group multiple v-model="cd.canaisSelected">
                                  <div v-for="canal in cd.canais">
                                    <v-item v-slot="{ active, toggle }" :value="canal">
                                      <div @click="toggle">
                                        <v-list-item :style="active ? 'background-color: #1976d2;' : ''" class="py-0">
                                          <v-list-item-title :style="active ? 'color: white;' : ''">{{ canal }}</v-list-item-title>
                                        </v-list-item>
                                      </div>
                                    </v-item>
                                  </div>
                                </v-item-group>
                              </v-list-group>
                            </v-list-group>
                          </v-list>
                        </v-card>
                      </v-expansion-panel>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
          </div>

          <div v-if="stepReplicManual == 2" style="width: 100vw;" v-for="transp in listTransp"
            class="d-flex flex-wrap mt-2">
            <v-item v-for="cd in transp.cds" class="widthCardReplic pb-3" v-slot="{ active, toggle }" :value="transp">
              <div>
                <v-card style="border-radius: 10px; background-color: #ebebeb;">
                  <v-card-text style="font-weight: bolder; color: black;" class="pb-0">{{ cd.nome }}</v-card-text>
                  <v-list expand style="background-color: #ebebeb;" dense>
                    <v-list-group :value="switchTrue" v-if="cd.docas.length > 0">
                      <template v-slot:activator>
                        <v-list-item-title>Docas</v-list-item-title>
                      </template>

                      <div>
                        <v-item-group multiple v-model="cd.docasSelected">
                          <div v-for="doca in cd.docas">
                            <v-item v-slot="{ active, toggle }" :value="doca">
                              <div @click="toggle">
                                <v-list-item :style="active ? 'background-color: #1976d2;' : ''"
                                  @click="funcListItem(transp.img, cd, doca, 1, 0)">
                                  <v-list-item-title :style="active ? 'color: white;' : ''">{{ doca }}</v-list-item-title>
                                </v-list-item>
                              </div>
                            </v-item>
                          </div>
                        </v-item-group>
                      </div>
                    </v-list-group>
                    <v-list-group :value="switchTrue" v-if="cd.canais.length > 0">
                      <template v-slot:activator>
                        <v-list-item-title>Canais</v-list-item-title>
                      </template>

                      <div>
                        <v-item-group multiple v-model="cd.canaisSelected">
                          <div v-for="canal in cd.canais">
                            <v-item v-slot="{ active, toggle }" :value="canal">
                              <div @click="toggle">
                                <v-list-item :style="active ? 'background-color: #1976d2;' : ''"
                                  @click="funcListItem(transp.img, cd, canal, 0, 1)">
                                  <v-list-item-title :style="active ? 'color: white;' : ''">{{ canal }}</v-list-item-title>
                                </v-list-item>
                              </div>
                            </v-item>
                          </div>
                        </v-item-group>
                      </div>
                    </v-list-group>
                  </v-list>
                </v-card>
              </div>
            </v-item>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- Fim Replic Manual -->

    <!-- Inicio Resumo Replic -->
    <v-dialog v-model="modal.resumo" fullscreen :scrim="false" transition="dialog-bottom-transition">
      <v-card flat class="overflow-hidden">
        <v-toolbar dark style="background-color: rgb(1, 134, 86);">
          <v-btn icon>
            <v-icon>fas fa-cog</v-icon>
          </v-btn>
          <v-toolbar-title>Resumo das Alterações</v-toolbar-title>
          <v-toolbar-title class="ml-2"
            v-if="radioOption == 'option05' || radioOption == 'option06' || radioOption == 'option07'">
            (Transportadora {{ transpNome }})
          </v-toolbar-title>
          <v-toolbar-title v-if="selectionTransp.length == 1 || listTransp.length == 1 && radioOption == ''" class="ml-2">
            <span v-if="selectionTransp.length == 1">
              (Transportadora {{ selectionTransp[0] }})
            </span>
            <span v-if="listTransp.length == 1">
              (Transportadora {{ listTransp[0].nome }})
            </span>
          </v-toolbar-title>
          <v-toolbar-title class="ml-2" v-if="radioOption == 'option01'">({{ docaNome }} - {{ nomeCd }})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="confirmAlter()" class="mr-2" color="primary">Replicar</v-btn>
          <v-btn icon @click="modal.resumo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-toolbar style="background-color: #E0E0E0;" dense class="elevation-1 mt-1">
            <v-icon>fas fa-arrow-right</v-icon>
            <v-card-title>Itens Alterados: </v-card-title>
            <v-card flat style="background-color: #E0E0E0 !important; height: 100%;" class="cardStyle"
              v-for="item in selectedOption">
              <div class="justify-center">
                <v-card-text class="pb-0 pt-1 d-flex justify-center" style="font-weight: bolder;">{{ item }}</v-card-text>
                <v-card-text class="d-flex justify-center pt-0" v-if="item == 'Nome'">{{ editedItem.transportadora.nome }}</v-card-text>
                <v-card-text class="d-flex justify-center pt-0" v-if="item == 'Serviço'">{{ editedItem.transportadora.servico }}</v-card-text>
                <v-card-text class="d-flex justify-center pt-0" v-if="item == 'CNPJ'">{{ editedItem.transportadora.cnpj }}</v-card-text>
                <v-card-text class="d-flex justify-center pt-0" v-if="item == 'Acréscimo Frete (%)'">{{ editedItem.transportadora.acresFrete }}</v-card-text>
                <v-card-text class="d-flex justify-center pt-0" v-if="item == 'Prazo Adicional'">{{ editedItem.transportadora.prazoAdc }}</v-card-text>
              </div>
            </v-card>
          </v-toolbar>
          <v-divider></v-divider>

          <template v-if="listTransp.length == 0">
            <div class="text-center">
              <v-card-subtitle>
                Você não possui transportadoras ativas na opção selecionada.
              </v-card-subtitle>
            </div>
          </template>
          <v-item-group multiple>
            <v-row class="ma-2" dense>
              <!-- Automático -->
              <v-col cols="6" sm="3" md="2" v-for="transp in listTransp"
                v-if="radioOption.length > 0 && radioOption != 'option05' && radioOption != 'option06' && radioOption != 'option07'">
                <v-item v-slot="{ active, toggle }" :value="transp">
                  <div>
                    <v-card v-if="radioOption == 'option01'" class="cardHover pb-0"
                      style="height: 12vh; display: flex; justify-content: center; align-items: center;">
                      <div>
                        <v-card-text class="text-center" style="font-weight: bolder">{{ transp.nome }}</v-card-text>
                      </div>
                      <!-- <div class="justify-center" style="display: grid; align-items: center; height: 50%; width: 70%;">
                        <v-img contain :src="transp.img" style="background-size: cover;"></v-img>          
                      </div> -->
                    </v-card>
                    <v-card v-if="radioOption != 'option01'" @click="toggle" class="cardHover pb-0"
                      style="height: 12vh; display: flex; justify-content: center; align-items: center;">
                      <div>
                        <v-card-text class="text-center" style="font-weight: bolder">{{ transp.nome }}</v-card-text>
                      </div>
                      <!-- <div class="justify-center" style="display: grid; align-items: center; height: 50%; width: 70%;">
                        <v-img contain :src="transp.img" style="background-size: cover;"></v-img>          
                      </div> -->
                    </v-card>
                    <v-expansion-panels>
                      <v-expansion-panel class="pt-0" v-if="active && radioOption != 'option01'">
                        <v-card class="pt-0" style="border-top: 1px solid #757575;">
                          <v-list class="pt-0" expand open-strategy="multiple" dense>
                            <v-list-group class="py-0" style="border-top: 1px solid #ebebeb;" dense
                              v-for="cd in transp.cds">
                              <template v-slot:activator>
                                <span class="ml-0 titleList"
                                  style="font-size: 13px; color: rgba(0, 0, 0, 0.6); font-weight: bolder;">{{
                                    cd.nome }}</span>
                                <v-spacer></v-spacer>
                              </template>

                              <v-list-group v-if="radioOption != 'option03' && cd.docas.length > 0"
                                style="border-top: 1px solid #ebebeb" class="activeGroup py-0" dense sub-group no-action :value="true">
                                <template v-slot:activator>
                                  <v-list-item-title style="position: relative; right: 20%;">Docas</v-list-item-title>
                                  <v-list-item-icon class="mr-0" style="position: relative; left: 13.5px;">
                                    <v-icon size="medium" class="mr-0" dense>fas fa-angle-down</v-icon>
                                  </v-list-item-icon>
                                </template>

                                <div>
                                  <v-list-item style="display: inline-flex;" v-for="doca in cd.docas">
                                    <v-list-item-title>{{ doca }}</v-list-item-title>
                                  </v-list-item>
                                </div>
                              </v-list-group>
                              <v-list-group v-if="radioOption != 'option02' && cd.canais.length > 0"
                                style="border-top: 1px solid #ebebeb" class="activeGroup py-0" dense sub-group no-action :value="true">
                                <template v-slot:activator>
                                  <v-list-item-title style="position: relative; right: 20%;">Canais</v-list-item-title>
                                  <v-list-item-icon class="mr-0" style="position: relative; left: 13.5px;">
                                    <v-icon size="medium" class="mr-0" dense>fas fa-angle-down</v-icon>
                                  </v-list-item-icon>
                                </template>

                                <div>
                                  <div v-if="radioOption.length > 0">
                                    <v-list-item v-for="canal in cd.canais">
                                      <v-list-item-title>{{ canal }}</v-list-item-title>
                                    </v-list-item>
                                  </div>

                                  <div v-if="radioOption.length == 0">
                                    <v-list-item v-for="canal in transp.canais.nome" v-if="transp.canais.nome.length > 0">
                                      <v-list-item-title>{{ canal }}</v-list-item-title>
                                    </v-list-item>
                                  </div>
                                </div>
                              </v-list-group>
                            </v-list-group>
                          </v-list>
                        </v-card>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-item>
              </v-col>
              <div class="d-flex flex-wrap" style="width: 100vw;" v-for="transp in listTransp"
                v-if="radioOption == 'option05' || radioOption == 'option06' || radioOption == 'option07'">
                <v-item v-for="cd in transp.cds" class="widthCardReplic pb-3" :value="cd">
                  <div>
                    <v-card style="border-radius: 10px; background-color: #ebebeb;">
                      <v-card-text style="font-weight: bolder; color: black;" class="pb-0">{{ cd.nome }}</v-card-text>
                      <v-list expand style="background-color: #ebebeb;" dense>
                        <v-list-group v-if="radioOption == 'option05' || radioOption == 'option07' && cd.docas.length > 0">
                          <template v-slot:activator>
                            <v-list-item-title>Docas</v-list-item-title>
                          </template>
                          <v-list-item v-for="doca in cd.docas">
                            <v-list-item-title>{{ doca }}</v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                        <v-list-group
                          v-if="radioOption == 'option06' || radioOption == 'option07' && cd.canais.length > 0">
                          <template v-slot:activator>
                            <v-list-item-title>Canais</v-list-item-title>
                          </template>
                          <v-list-item v-for="canal in cd.canais">
                            <v-list-item-title>{{ canal }}</v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-list>
                    </v-card>
                  </div>
                </v-item>
              </div>
              <!-- Manual -->
              <v-col v-if="radioOption.length == 0 && selectionTransp.length > 1" v-for="transp2 in selectionTransp"
                cols="6" sm="3" md="2">
                <v-item v-for="transp in listTransp" v-if="transp.nome == transp2" v-slot="{ active, toggle }"
                  :value="transp">
                  <div>
                    <v-card @click="toggle" class="cardHover pb-0"
                      style="height: 12vh; display: flex; justify-content: center; align-items: center;">
                      <v-card-text class="text-center" style="font-weight: bolder">{{ transp.nome }}</v-card-text>
                      <!-- <div class="justify-center" style="display: grid; align-items: center; height: 50%; width: 70%;">
                        <v-img contain :src="transp.img" style="background-size: cover;"></v-img>          
                      </div> -->
                    </v-card>
                    <v-expansion-panels>
                      <v-expansion-panel class="pt-0" v-if="active">
                        <v-card class="pt-0" style="border-top: 1px solid #757575;">
                          <v-list class="pt-0" dense expand open-strategy="multiple">
                            <v-list-group v-for="cd in transp.cds" style="border-top: 1px solid #ebebeb" dense
                              v-if="cd.hasOwnProperty('docasSelected') || cd.hasOwnProperty('canaisSelected')">
                              <template v-slot:activator>
                                <span class="ml-0 titleList" style="font-size: 12px; color: rgba(0, 0, 0, 0.6); font-weight: bolder;">
                                  {{ cd.nome }}
                                </span>
                                <v-spacer></v-spacer>
                              </template>

                              <v-list-group v-if="cd.hasOwnProperty('docasSelected')"
                                style="border-top: 1px solid #ebebeb;" class="activeGroup" dense sub-group no-action
                                :value="true">
                                <template v-slot:activator>
                                  <v-list-item-title style="position: relative; right: 20%;"
                                    class="ml-0">Docas</v-list-item-title>
                                  <v-list-item-icon class="mr-0" style="position: relative; left: 13.5px;">
                                    <v-icon size="medium" class="mr-0" dense>fas fa-angle-down</v-icon>
                                  </v-list-item-icon>
                                </template>

                                <div>
                                  <v-list-item v-for="doca in cd.docasSelected">
                                    <v-list-item-title>{{ doca }}</v-list-item-title>
                                  </v-list-item>
                                </div>
                              </v-list-group>

                              <v-list-group v-if="cd.hasOwnProperty('canaisSelected')" :value="true" class="activeGroup"
                                style="border-top: 1px solid #ebebeb;" dense sub-group no-action>
                                <template v-slot:activator>
                                  <v-list-item-title style="position: relative; right: 20%;">Canais</v-list-item-title>
                                  <v-list-item-icon class="mr-0" style="position: relative; left: 13.5px;">
                                    <v-icon size="medium" class="mr-0" dense>fas fa-angle-down</v-icon>
                                  </v-list-item-icon>
                                </template>

                                <div>
                                  <v-list-item v-for="canal in cd.canaisSelected">
                                    <v-list-item-title>{{ canal }}</v-list-item-title>
                                  </v-list-item>
                                </div>
                              </v-list-group>
                            </v-list-group>
                          </v-list>
                        </v-card>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-item>
              </v-col>
              <div v-if="listTransp.length == 1 || selectionTransp.length == 1" v-for="transp in listTransp"
                style="width: 100vw;" class="d-flex flex-wrap">
                <v-item v-for="cd in transp.cds" v-if="cd.hasOwnProperty('docasSelected') || cd.hasOwnProperty('canaisSelected')"                  
                  class="widthCardReplic pb-3" v-slot="{ active, toggle }" :value="transp">
                  <div>
                    <v-card style="border-radius: 10px; background-color: #ebebeb;">
                      <v-card-text style="font-weight: bolder; color: black;" class="pb-0">{{ cd.nome }}</v-card-text>
                      <v-list expand style="background-color: #ebebeb;" dense>
                        <v-list-group v-if="cd.hasOwnProperty('docasSelected')">
                          <template v-slot:activator>
                            <v-list-item-title>Docas</v-list-item-title>
                          </template>
                          <v-list-item v-for="doca in cd.docasSelected">
                            <v-list-item-title>{{ doca }}</v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                        <v-list-group v-if="cd.hasOwnProperty('canaisSelected')">
                          <template v-slot:activator>
                            <v-list-item-title>Canais</v-list-item-title>
                          </template>
                          <v-list-item v-for="canal in cd.canaisSelected">
                            <v-list-item-title>{{ canal }}</v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-list>
                    </v-card>
                  </div>
                </v-item>
              </div>
            </v-row>
          </v-item-group>
        </div>
      </v-card>
    </v-dialog>
    <!-- Fim Resumo Replic -->

    <!-- Inicio Config -->
    <v-dialog v-model="modal.config" fullscreen :scrim="false" transition="dialog-bottom-transition">
      <v-card class="overflow-hidden">
        <v-toolbar dark style="background-color: rgb(1, 134, 86);">
          <v-btn icon>
            <v-icon>fas fa-warehouse</v-icon>
          </v-btn>
          <v-toolbar-title>Configurações da Doca ({{ docaNome }})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn type="button" width="120px" v-if="saveData" color="primary" @click="saveConfig()" class="mr-2">
            Salvar
          </v-btn>
          <v-btn icon @click="modal.config = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>

        <v-row class="d-flex justify-center mt-5 pb-8">
          <v-expansion-panels class="expanGroupDesign" multiple>
            <v-expansion-panel class="expanStyle">
              <v-expansion-panel-header class="expanHeader">Frete Grátis</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat style="padding: 10px;">
                  <div>
                    <v-row>
                      <v-col cols="5">
                        <div class="ml-4">
                          <legend style="padding-left: 10px;"></legend>
                          <v-card-title style="height: 1px; position: relative; bottom: 15px;">
                            2ª Opção:
                          </v-card-title>
                          <div style="display: flex;">
                            <v-switch v-model="detalhes.freteGratis.freteGratis2Opcao" @change="btnSave"
                              label="O Frete grátis trará uma segunda opção de frete para o Consumidor."></v-switch>
                          </div>
                          <div style="display: flex;">
                            <v-switch v-model="detalhes.freteGratis.freteGratisTodas" @change="btnSave"
                              label="O Frete grátis trará todas as opções de frete para o Consumidor."></v-switch>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="1" style="display: flex; justify-content: end;">
                        <v-divider vertical></v-divider>
                      </v-col>
                      <v-col cols="4" style="bottom: 10px; position: relative;">
                        <div class="ml-4">
                          <v-card-title style="position: relative; left: 10px;">Retorna Serviço:</v-card-title>
                          <div style="display: flex; position: relative; left: 10px; bottom: 20px;">
                            <v-switch v-model="detalhes.freteGratis.freteGratisRetornaServico" @change="btnSave"
                              label="Retorna Serviço na frente do frete grátis (ex. Frete grátis - EXPRESSO)."></v-switch>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="expanStyle">
              <v-expansion-panel-header class="expanHeader">Bloqueios</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card style="padding: 10px;" flat>
                  <v-row>
                    <v-col cols="4" style="position: relative;">
                      <v-card-title style="height: 1px;
													position: relative;
													bottom: 15px;
													font-size: 16px;
													">Frete Valor Limite:</v-card-title>
                      <div style="
													position: relative;
													left: 50px;
													top: 10px;">
                        <v-text-field type="number" clearable outlined label="Valor:"
                          v-model="detalhes.bloqueios.freteValorLimite" @input="btnSave">
                        </v-text-field>
                      </div>
                      <v-switch class="ml-8 d-inline-flex" @change="btnSave" label="Redução de Custo"
                        v-model="detalhes.bloqueios.reducaoCusto"></v-switch>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col cols="5">
                      <v-card-title style="height: 1px;
										position: relative;
										bottom: 15px;
										font-size: 16px;
										">Frete Percentual Limite:</v-card-title>
                      <div style="position: relative; left: 50px; top: 10px;">
                        <v-text-field type="number" clearable v-model="detalhes.bloqueios.fretePercentualLimite" outlined
                          @input="btnSave" label="Valor(%):">
                        </v-text-field>
                      </div>
                      <div>
                        <v-card-subtitle><b>Nota:</b> Esta configuração é utilizada para opção do
                          Menu Autorizações!</v-card-subtitle>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="expanStyle">
              <v-expansion-panel-header class="expanHeader">Transportadora
                Prioritária/Redespacho</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card style="padding: 10px;" flat>
                  <v-row>
                    <v-col cols="5">
                      <legend style="padding-left: 10px;"></legend>
                      <v-card-title style="height: 1px; position: relative; bottom: 15px;">
                        Transportadora Prioritária:
                      </v-card-title>
                      <div style="display: flex; padding: 20px;">
                        <v-select @change="btnSave" v-model="detalhes.transpPrioritariaRed.transpPrio" label="Escolha"
                          :items="listTranspSelect"></v-select>
                      </div>
                      <div>
                        <v-card-subtitle><b>Nota:</b> Quando escolher uma transportadora prioritária
                          , caso ela apareça na cotação ,as outras são retiradas e exibe somente a
                          selecionada!</v-card-subtitle>
                      </div>
                    </v-col>
                    <v-col cols="1" style="display: flex; justify-content: end;">
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col cols="5" class="ml-4">
                      <v-card-title style="height: 1px; position: relative; bottom: 15px; right: 40px;">
                        Transportadora Redespacho:
                      </v-card-title>
                      <div>
                        <v-select @change="btnSave" v-model="detalhes.transpPrioritariaRed.transpRed"
                          style="margin-top: 25px;" label="Escolha" :items="listTranspSelect"></v-select>
                      </div>
                      <div>
                        <v-text-field v-model="detalhes.transpPrioritariaRed.transpRedCep" style="width: 70%;" dense
                          outlined type="number" clearable label="CEP Calcular Redespacho"
                          @input="btnSave"></v-text-field>
                        <v-card-subtitle><b>Nota:</b> Transportadora <b>BASE</b> utilizada para o
                          redespacho!</v-card-subtitle>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="expanStyle">
              <v-expansion-panel-header class="expanHeader">Medidas</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card style="padding: 10px;" flat>
                  <v-row>
                    <v-col cols="10">
                      <legend style="padding-left: 10px;"></legend>
                      <v-card-title style="height: 1px; position: relative; bottom: 15px; font-size: 19px;">
                        Buscar as medidas para cotação da tabela de produtos:
                      </v-card-title>
                      <div style="display: flex; padding: 20px; height: 80px;">
                        <v-switch v-model="detalhes.medidas.cotacaoMedidasProdutoBaseSis" @change="btnSave"
                          label="Cotação pelas medidas do produto importado na base Sisfrete."></v-switch>
                      </div>
                      <v-card-subtitle><b>Nota:</b> Mesmo enviando as medidas será cotado pelas
                        medidas do produto importado na base Sisfrete.</v-card-subtitle>
                    </v-col>
                  </v-row>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="expanStyle">
              <v-expansion-panel-header class="expanHeader">Frete Normal/Expresso</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card style="padding: 10px;" flat>
                  <v-row>
                    <v-col cols="12">
                      <legend style="padding-left: 10px;"></legend>
                      <v-card-title style="height: 1px; position: relative; bottom: 15px;">
                        Habilitar opções de Frete Normal e Expresso:
                      </v-card-title>
                      <v-row style="justify-content: space-evenly; align-items: end;">
                        <div style="display: flex; margin: 10px;">
                          <v-switch v-model="detalhes.freteNormalExpress.freteNormal" @change="btnSave"
                            label="Habilitar Frete Normal e Expresso"></v-switch>
                        </div>
                        <div style="display: flex; margin: 10px">
                          <v-switch v-model="detalhes.freteNormalExpress.retornoUnico" @change="btnSave" color="#000000"
                            label="Retorno único no empate de prazo"></v-switch>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="expanStyle">
              <v-expansion-panel-header class="expanHeader">Cep Origem Base TrayCorp</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card style="padding: 10px;" flat>
                  <v-row>
                    <v-col cols="12">
                      <legend style="padding-left: 10px;"></legend>
                      <v-card-title style="height: 1px; position: relative; bottom: 15px;">
                        Cep Origem Base TrayCorp
                      </v-card-title>
                    </v-col>
                  </v-row>
                  <v-row style="justify-content: center; align-items: end;">
                    <v-col cols="12" sm="6" md="3" xl="2" style="margin: 10px;">
                      <v-text-field v-model="detalhes.cepOrigemBaseTray.cepOrigemTray" clearable type="number"
                        outlined label="Cep Origem Base TrayCorp" @input="btnSave"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- Final Config -->

    <!-- Inicio Principal -->
    <v-card v-for="cd in cds" v-if="cd.nome == nomeCd" height="100%" class="overflow-hidden" flat>
      <v-toolbar flat>
        <v-icon size="200">fas fa-warehouse</v-icon>
        <v-toolbar-title class="pl-4">Docas</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="configAdd()">Adicionar</v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <template v-if="cd.docas.length == 0">
        <div class="text-center">
          <v-card-subtitle>Você não possui Docas cadastradas nesse Centro de Distribuição</v-card-subtitle>
        </div>
      </template>
      <template v-else-if="carregando">
        <v-row style="height: 100%;" dense align="start" justify="center">
          <v-progress-circular class="mt-4" indeterminate color="#018656"></v-progress-circular>
        </v-row>
      </template>
      <template v-else>
        <v-row class="ma-2" dense>
          <v-col v-for="(doca, index) in cd.docas" :key="index" cols="6" sm="3" md="2">
            <v-card class="cardStyleImg" @click="openDoca(doca)">
              <v-img :src="doca.img" contain style="background-size: cover; position: relative; left: 12%;" height="120"
                width="80%"></v-img>
              <v-divider></v-divider>
              <v-card-actions class="py-0">
                <v-card-text style="font-weight: bolder;" class="pl-2">{{ doca.nome }}</v-card-text>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-card>
    <!-- Fim Principal -->
  </div>
</template>

<script>
import { baseApiUrl } from '@/global'
import axios from 'axios'

export default {
  name: "Docas",
  props: {
    listCds: { type: Array },
    nomeCd: { type: String },
  },
  data: () => ({
    carregando: false,
    page: 1,
    cds: {},
    selectManual: false,
    switchTrue: false,
    grid: {
      transportadoras: {
        items: [],
        carregando: false,
        size: 0,
      },
    },

    // Inicializa variáveis para Configuração da Doca
    detalhes: {
      freteGratis: {
        freteGratis2Opcao: null,
        freteGratisTodas: null,
        freteGratisRetornaServico: null,
      },
      bloqueios: {
        freteValorLimite: null,
        fretePercentualLimite: null,
        reducaoCusto: null,
      },
      transpPrioritariaRed: {
        transpPrio: null,
        transpRed: null,
        transpRedCep: null,
      },
      medidas: {
        cotacaoMedidasProdutoBaseSis: null,
      },
      freteNormalExpress: {
        freteNormal: null,
        retornoUnico: null,
      },
      cepOrigemBaseTray: {
        cepOrigemTray: null,
      },
    },
    /* ------------------------------------------------ */
    
    transportadora: {
      nome: null,
    },

    // Inicializa variáveis para edição de transportadora
    editedItem: {
      transportadora: {
        nome: null,
        servico: null,
        cnpj: null,
        acresFrete: null,
        prazoAdc: null,
        cotacaoWeb: null,
        prioriRegistro: null,
        redespacho: null,
        sempreVisivel: null,
      },
    },
    // Aplica validações para criação de Doca
    rulesChar: [
      value => {
        if (value) return true

        return 'Este campo é obrigatório.'
      }
    ],

    // Itens para Replicação
    reOptions: ['Nome', 'Serviço', 'CNPJ', 'Acréscimo Frete (%)', 'Prazo Adicional'],

    // Inicializa variáveis para Adição de Doca
    newDoca: {
      nome: null,
      token: null,
      imagem: null,
    },

    // Telas
    modal: {
      add: false,
      doca: false,
      transp: false,
      config: false,
      replic: false,
      manual: false,
      automat: false,
      modais: false,
      resumo: false,
    },
    modais: ['Exclusivo', 'Vidro', 'Líquido', 'Químico', 'Volumoso', 'Pesado'],
    docaNome: '',
    transpNome: '',
    saveData: false,
    step: 0,
    stepReplicManual: 0,
    selectedOption: [],
    radioOption: [],
    switchModais: [],
    selectionTransp: [],
    selectAll: [],
    tbRes: 0,
    listTransp: [],
    allTranspBtn: false,
    transpBtn: false,
    btnReplic: false,
    active: false,
    btnAutomat: false,
    listTranspSelect: [],
  }),
  mounted() {
    this.carregarDocas();
  },
  methods: {
    btnSave() {
      this.saveData = true;
    },

    // Quando o usuário clica no icone de 'Replicar' 
    openReplic() {
      this.modal.replic = true;
      this.radioOption = [];
      this.step = 1;
      this.selectedOption = [];
      this.selectAll = [];
      this.btnReplic = false;
    },
    fechar() {
      this.$router.push("/");
    },

    // Carrega todas as docas vinculadas ao Centro de Distribuição selecionado
    carregarDocas() {
      this.carregando = true;
      this.cds = this.listCds;

      setTimeout(() => (this.carregando = false), 1000);
    },

    // Quando o usuário clica no botão de 'Adicionar' Doca
    configAdd() {
      this.modal.add = true;
      this.newDoca.nome = null;
      this.newDoca.token = null;
      this.newDoca.imagem = null;
      this.resetValidation();
    },

    // Reseta a validação do formulário
    resetValidation(){
      this.$refs.form.resetValidation()
    },

    // Função quando clica na Doca
    openDoca(item){
      this.modal.doca = true;
      this.docaNome = item.nome;
      this.docaToken = item.token;
      this.carregarTransportadoras();
    },

    // Função para chamar as transportadoras
    carregarTransportadoras(){
      this.page = 1;
      this.grid.transportadoras.size = 0;
      this.grid.transportadoras.carregando = true
      let url = `${baseApiUrl}/transportadoras`

      axios.get(url)
        .then(res => {
          // Chama todas as transportadoras que o cliente tem cadastrado
          this.grid.transportadoras.items = res.data.transportadoras

          for (var item in this.grid.transportadoras.items) {
            this.grid.transportadoras.size = parseInt(this.grid.transportadoras.size + 1);
          }

          // Cálculo para montar a paginação no painel 'Transportadoras Vinculadas'
          this.grid.transportadoras.size = Math.ceil(this.grid.transportadoras.size / 24);

          for(var cd in this.listCds){
            if(this.listCds[cd].nome == this.nomeCd){
              for(var doca in this.listCds[cd].docas){
                if(this.listCds[cd].docas[doca].nome == this.docaNome){
                  // Verifica se a doca já não possui transportadoras
                  if(Object.keys(this.listCds[cd].docas[doca]).length == 3){
                    // Se não possuir, atribui as transportadoras a Doca
                    this.listCds[cd].docas[doca].transportadoras = this.grid.transportadoras.items;
                  }
                }
              }
            }
          }
        })
        .finally(() => this.grid.transportadoras.carregando = false, this.paginacaoTransps(this.page))
    },

    // Função para quando o usuário mudar a paginação no painel 'Transportadoras Vinculadas'
    paginacaoTransps(item) {
      this.end = 24;
      this.end = this.end * item;
      this.start = this.end - (this.end / item) + 1;
    },

    // Quando o usuário clica na transportadora
    openTransp(item) {
      this.modal.transp = true;
      this.switchModais = [];
      this.transportadora.nome = item.transportadora;
      this.editedItem.transportadora.nome = item.transportadora;
      this.editedItem.transportadora.servico = item.servico;
      this.editedItem.transportadora.cnpj = item.cnpj;
      this.editedItem.transportadora.acresFrete = item.acresFrete;
      this.editedItem.transportadora.prazoAdc = item.prazoAdc;
      this.editedItem.transportadora.cotacaoWeb = item.cotacaoWeb;
      this.editedItem.transportadora.prioriRegistro = item.prioriRegistro;
      this.editedItem.transportadora.redespacho = item.redespacho;
      this.editedItem.transportadora.sempreVisivel = item.sempreVisivel;
      this.switchModais = item.modais;
      this.transpNome = item.transportadora;
    },

    // Função para criar a Doca
    async createdDoca() {
      this.$refs.form.validate();
      if(this.$refs.form.validate() == true){
        for(var cd in this.listCds){
          if(this.listCds[cd].nome == this.nomeCd){
            this.listCds[cd].docas.push({
              nome: this.newDoca.nome,
              img: this.newDoca.imagem,
              token: this.newDoca.token,
            })
          }
        }
        console.log(this.listCds);
        this.modal.add = false;
        this.carregarDocas();
      }
    },

    // Quando o usuário clica no botão de configurações da Doca
    montaConfig(){
      this.listTranspSelect = [];
      this.modal.config = true;
      this.saveData = false;
      for(var cd in this.cds){
        if(this.cds[cd].nome == this.nomeCd){
          for(var doca in this.cds[cd].docas){
            if(this.cds[cd].docas[doca].nome == this.docaNome){
              for (var transp in this.cds[cd].docas[doca].transpSelect) {
                this.listTranspSelect.push(this.cds[cd].docas[doca].transpSelect[transp]);
              }
            }
          }
        }
      }
    },

    // Salva as alterações que ele fez na tela de Configurações da Doca
    async saveConfig() {
      this.$swal({
        title: 'Alerta',
        html: `Salvar as alterações ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Concluir',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
        .then(async (result) => {

          if (result.isConfirmed) {
            this.$swal({
              title: 'Sucesso!!!',
              text: 'Informações Alteradas com sucesso.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          } else {
            this.$swal({
              title: 'Que pena!!!',
              text: 'Alterações não salvas.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          }
          this.modal.config = false;
        },
        )
    },

    // Salvar alterações da transportadora
    async saveTransp() {
      for (var cd in this.cds) {
        if (this.cds[cd].nome == this.nomeCd) {
          for (var doca in this.cds[cd].docas) {
            if (this.cds[cd].docas[doca].nome == this.docaNome) {
              for (var transp in this.cds[cd].docas[doca].transportadoras) {
                if (this.cds[cd].docas[doca].transportadoras[transp].transportadora == this.transpNome) {
                  this.cds[cd].docas[doca].transportadoras[transp].transportadora = this.editedItem.transportadora.nome;
                  this.cds[cd].docas[doca].transportadoras[transp].servico = this.editedItem.transportadora.servico;
                  this.cds[cd].docas[doca].transportadoras[transp].cnpj = this.editedItem.transportadora.cnpj;
                  this.cds[cd].docas[doca].transportadoras[transp].acresFrete = this.editedItem.transportadora.acresFrete;
                  this.cds[cd].docas[doca].transportadoras[transp].prazoAdc = this.editedItem.transportadora.prazoAdc;
                  this.cds[cd].docas[doca].transportadoras[transp].cotacaoWeb = this.editedItem.transportadora.cotacaoWeb;
                  this.cds[cd].docas[doca].transportadoras[transp].prioriRegistro = this.editedItem.transportadora.prioriRegistro;
                  this.cds[cd].docas[doca].transportadoras[transp].redespacho = this.editedItem.transportadora.redespacho;
                  this.cds[cd].docas[doca].transportadoras[transp].sempreVisivel = this.editedItem.transportadora.sempreVisivel;
                  this.cds[cd].docas[doca].transportadoras[transp].modais = this.switchModais;
                  console.log(this.cds[cd].docas[doca].transportadoras[transp]);
                }
              }
            }
          }
        }
      }
      this.modal.transp = false;
    },

    // Quando clica no botão 'Automático' na tela de Replicação
    automat() {
      this.radioOption = [];
      this.selectionTransp = [];
      this.allTranspBtn = false;
      this.transpBtn = false;
      this.btnReplic = false;
    },

    // Quando clica no botão 'Manual' na tela de Replicação
    manual() {
      this.modal.manual = true;
      this.radioOption = [];
      this.selectionTransp = [];
      this.stepReplicManual = 1;
      this.selectManual = false;
      this.switchTrue = false;
    },

    // Quando o usuário clica no botão de 'Replicar' na tela de Resumo de Configurações
    confirmAlter() {
      this.$swal({
        title: 'Alerta',
        html: `Deseja confirmar as alterações?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Concluir',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })

        // Se ele clicar em 'Concluir'
        .then(async (result) => {
          if (result.isConfirmed) {

            // Se ele tiver optado pela replicação Automática
            if(this.radioOption != ""){
              for(var cd in this.cds){

                // Se ele tiver selecionado 'Doca Atual'
                if(this.radioOption == 'option01'){
                  if(this.cds[cd].nome == this.nomeCd){
                    for(var doca in this.cds[cd].docas){
                      if(this.cds[cd].docas[doca].nome == this.docaNome){
                        for(var transpSelect in this.cds[cd].docas[doca].transpSelect){
                          for(var transp in this.cds[cd].docas[doca].transportadoras){
                            if(this.cds[cd].docas[doca].transpSelect[transpSelect] == this.cds[cd].docas[doca].transportadoras[transp].transportadora){
                              // Verifica os itens que o usuário selecionou no início da replicação
                              for(var item in this.selectedOption){
                                if(this.selectedOption[item] == 'Acréscimo Frete (%)'){
                                  this.cds[cd].docas[doca].transportadoras[transp].acresFrete = this.editedItem.transportadora.acresFrete;
                                }
                                if(this.selectedOption[item] == 'Prazo Adicional'){
                                  this.cds[cd].docas[doca].transportadoras[transp].prazoAdc = this.editedItem.transportadora.prazoAdc;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  console.log(this.listCds);
                }

                // Se ele tiver selecionado 'Todas as docas ou Todos os canais de venda e Docas'
                if(this.radioOption == 'option02' || this.radioOption == 'option04'){
                  for(var doca in this.cds[cd].docas){
                    for(var transpSelect in this.cds[cd].docas[doca].transpSelect){
                      for(var transp in this.cds[cd].docas[doca].transportadoras){
                        if(this.cds[cd].docas[doca].transpSelect[transpSelect] == this.cds[cd].docas[doca].transportadoras[transp].transportadora){
                          // Verifica os itens que o usuário selecionou no início da replicação
                          for(var item in this.selectedOption){
                            if(this.selectedOption[item] == 'Acréscimo Frete (%)'){
                              this.cds[cd].docas[doca].transportadoras[transp].acresFrete = this.editedItem.transportadora.acresFrete;
                            }
                            if(this.selectedOption[item] == 'Prazo Adicional'){
                              this.cds[cd].docas[doca].transportadoras[transp].prazoAdc = this.editedItem.transportadora.prazoAdc;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                // Se ele tiver selecionado 'Todos os canais ou todos os canais de venda e docas'
                if(this.radioOption == 'option03' || this.radioOption == 'option04'){
                  for(var canal in this.cds[cd].canais){
                    for(var transpSelect in this.cds[cd].canais[canal].transpSelect){
                      for(var transp in this.cds[cd].canais[canal].transportadoras){
                        if(this.cds[cd].canais[canal].transpSelect[transpSelect] == this.cds[cd].canais[canal].transportadoras[transp].transportadora){
                          // Verifica os itens que o usuário selecionou no início da replicação
                          for(var item in this.selectedOption){
                            if(this.selectedOption[item] == 'Acréscimo Frete (%)'){
                              this.cds[cd].canais[canal].transportadoras[transp].acresFrete = this.editedItem.transportadora.acresFrete;
                            }
                            if(this.selectedOption[item] == 'Prazo Adicional'){
                              this.cds[cd].canais[canal].transportadoras[transp].prazoAdc = this.editedItem.transportadora.prazoAdc;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                // Se ele tiver selecionado 'Todas as docas ou todos os Canais de Venda e Docas' em uma transportadora específica
                if (this.radioOption == 'option05' || this.radioOption == 'option07') {
                  for (var doca in this.cds[cd].docas) {
                    for (var transpSelect in this.cds[cd].docas[doca].transpSelect) {
                      if (this.cds[cd].docas[doca].transpSelect[transpSelect] == this.transpNome) {
                        for (var transp in this.cds[cd].docas[doca].transportadoras) {
                          if (this.cds[cd].docas[doca].transpSelect[transpSelect] == this.cds[cd].docas[doca].transportadoras[transp].transportadora) {
                            // Verifica os itens que o usuário selecionou no início da replicação
                            for(var item in this.selectedOption) {
                              if (this.selectedOption[item] == 'Nome') {
                                this.cds[cd].docas[doca].transportadoras[transp].transportadora = this.editedItem.transportadora.nome;
                              }
                              if(this.selectedOption[item] == 'Serviço') {
                                this.cds[cd].docas[doca].transportadoras[transp].servico = this.editedItem.transportadora.servico;
                              }
                              if(this.selectedOption[item] == 'CNPJ') {
                                this.cds[cd].docas[doca].transportadoras[transp].cnpj = this.editedItem.transportadora.cnpj;
                              }
                              if(this.selectedOption[item] == 'Acréscimo Frete (%)') {
                                this.cds[cd].docas[doca].transportadoras[transp].acresFrete = this.editedItem.transportadora.acresFrete;
                              }
                              if(this.selectedOption[item] == 'Prazo Adicional') {
                                this.cds[cd].docas[doca].transportadoras[transp].prazoAdc = this.editedItem.transportadora.prazoAdc;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                // Se ele tiver selecionado 'Todos os Canais ou todos os Canais de venda e Docas' em uma transportadora específica
                if (this.radioOption == 'option06' || this.radioOption == 'option07') {
                  for (var canal in this.cds[cd].canais) {
                    for (var transpSelect in this.cds[cd].canais[canal].transpSelect) {
                      if (this.cds[cd].canais[canal].transpSelect[transpSelect] == this.transpNome) {
                        for (var transp in this.cds[cd].canais[canal].transportadoras) {
                          if (this.cds[cd].canais[canal].transpSelect[transpSelect] == this.cds[cd].canais[canal].transportadoras[transp].transportadora) {
                            // Verifica os itens que o usuário selecionou no início da replicação
                            for (var item in this.selectedOption) {
                              if (this.selectedOption[item] == 'Nome') {
                                this.cds[cd].canais[canal].transportadoras[transp].transportadora = this.editedItem.transportadora.nome;
                              }
                              if (this.selectedOption[item] == 'Serviço') {
                                this.cds[cd].canais[canal].transportadoras[transp].servico = this.editedItem.transportadora.servico;
                              }
                              if (this.selectedOption[item] == 'CNPJ') {
                                this.cds[cd].canais[canal].transportadoras[transp].cnpj = this.editedItem.transportadora.cnpj;
                              }
                              if (this.selectedOption[item] == 'Acréscimo Frete (%)') {
                                this.cds[cd].canais[canal].transportadoras[transp].acresFrete = this.editedItem.transportadora.acresFrete;
                              }
                              if (this.selectedOption[item] == 'Prazo Adicional') {
                                this.cds[cd].canais[canal].transportadoras[transp].prazoAdc = this.editedItem.transportadora.prazoAdc;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            // Se ele tiver optado pela Replicação Manual
            if (this.radioOption == "") {
              for (var transp in this.listTransp) {
                for (var cd in this.listTransp[transp].cds) {
                  for (var cd2 in this.cds) {
                    if (this.listTransp[transp].cds[cd].nome == this.cds[cd2].nome) {
                      for (var doca in this.listTransp[transp].cds[cd].docasSelected) {
                        for (var doca2 in this.cds[cd2].docas) {
                          if (this.listTransp[transp].cds[cd].docasSelected[doca] == this.cds[cd2].docas[doca2].nome) {
                            for (var transp2 in this.cds[cd2].docas[doca2].transportadoras) {
                              if (this.cds[cd2].docas[doca2].transportadoras[transp2].transportadora == this.transpNome) {
                                for (var item in this.selectedOption) {
                                  if (this.selectedOption[item] == 'Nome') {
                                    this.cds[cd2].docas[doca2].transportadoras[transp2].transportadora = this.editedItem.transportadora.nome;
                                  }
                                  if (this.selectedOption[item] == 'Serviço') {
                                    this.cds[cd2].docas[doca2].transportadoras[transp2].servico = this.editedItem.transportadora.servico;
                                  }
                                  if (this.selectedOption[item] == 'CNPJ') {
                                    this.cds[cd2].docas[doca2].transportadoras[transp2].cnpj = this.editedItem.transportadora.cnpj;
                                  }
                                  if (this.selectedOption[item] == 'Acréscimo Frete (%)') {
                                    this.cds[cd2].docas[doca2].transportadoras[transp2].acresFrete = this.editedItem.transportadora.acresFrete;
                                  }
                                  if (this.selectedOption[item] == 'Prazo Adicional') {
                                    this.cds[cd2].docas[doca2].transportadoras[transp2].prazoAdc = this.editedItem.transportadora.prazoAdc;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      for (var canal in this.listTransp[transp].cds[cd].canaisSelected) {
                        for (var canal2 in this.cds[cd].canais) {
                          if (this.listTransp[transp].cds[cd].canaisSelected[canal] == this.cds[cd2].canais[canal2].nome) {
                            for (var transp2 in this.cds[cd2].canais[canal2].transportadoras) {
                              if (this.cds[cd2].canais[canal2].transportadoras[transp2].transportadora == this.listTransp[transp].nome) {
                                for (var item in this.selectedOption) {
                                  if (this.selectedOption[item] == 'Nome') {
                                    this.cds[cd2].canais[canal2].transportadoras[transp2].transportadora = this.editedItem.transportadora.nome;
                                  }
                                  if (this.selectedOption[item] == 'Serviço') {
                                    this.cds[cd2].canais[canal2].transportadoras[transp2].servico = this.editedItem.transportadora.servico;
                                  }
                                  if (this.selectedOption[item] == 'CNPJ') {
                                    this.cds[cd2].canais[canal2].transportadoras[transp2].cnpj = this.editedItem.transportadora.cnpj;
                                  }
                                  if (this.selectedOption[item] == 'Acréscimo Frete (%)') {
                                    this.cds[cd2].canais[canal2].transportadoras[transp2].acresFrete = this.editedItem.transportadora.acresFrete;
                                  }
                                  if (this.selectedOption[item] == 'Prazo Adicional') {
                                    this.cds[cd2].canais[canal2].transportadoras[transp2].prazoAdc = this.editedItem.transportadora.prazoAdc;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            this.$swal({
              title: 'Sucesso!!!',
              text: 'Informações Alteradas com sucesso.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });

          // Se ele clicar em 'Cancelar', fecha a janela
          } else {
            this.$swal({
              title: 'Que pena!!!',
              text: 'Alterações não salvas.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          }
          this.listCds = this.cds;
          this.modal.resumo = false;
          this.modal.manual = false;
          this.modal.replic = false;
        },
        )
    },
    selectedTransp(item) {
      if (item == 1) {
        for (var transp in this.listTransp) {
          this.selectionTransp.push(this.listTransp[transp].img);
        }
        this.selectionTransp = [...new Set(this.selectionTransp)];
      } else {
        this.selectionTransp = [];
      }
    },

    // Função do botão 'Selecionar todos' os itens na tela de Replicação de configurações
    selectItems(item) {
      this.step = 1;
      this.btnAutomat = false;
      if (item == 1) {
        for (var option in this.reOptions) {
          this.selectedOption.push(this.reOptions[option]);
        }
        this.selectedOption = [...new Set(this.selectedOption)];
      } else {
        this.selectedOption = [];
      }
    },

    // Função do botão 'Selecionar todas' as transportadoras na tela de Replicação de configurações
    selectAllManual(item) {
      if (item == true) {
        for (var transp in this.listTransp) {
          for (var cd in this.listTransp[transp].cds) {
            if (this.listTransp[transp].cds[cd].docas.length > 0) {
              this.listTransp[transp].cds[cd].docasSelected = this.listTransp[transp].cds[cd].docas;
              this.switchTrue = true;
            }
            if (this.listTransp[transp].cds[cd].canais.length > 0) {
              this.listTransp[transp].cds[cd].canaisSelected = this.listTransp[transp].cds[cd].canais;
              this.switchTrue = true;
            }
          }
        }
      }else{
        for (var transp in this.listTransp) {
          for (var cd in this.listTransp[transp].cds) {
            this.listTransp[transp].cds[cd].docasSelected = [];
            this.listTransp[transp].cds[cd].canaisSelected = [];
            this.switchTrue = false;
          }
        }
      }
    },

    /** Quando o usuário clica no botão 'Manual', trás todas as transportadoras e seus respectivos
    cds, canais de vendas e docas */
    montaTransps() {
      this.listTransp = [];

      /** Se na Replicação o usuário não tiver selecionado 'Nome', 'Serviço' ou 'CNPJ', irá listar
      todas as transportadoras */
      if (this.radioOption.length == 0 && (!this.selectedOption.find((element) => element == 'Nome') && !this.selectedOption.find((element) => element == 'Serviço') && !this.selectedOption.find((element) => element == 'CNPJ'))) {
        for (var cd in this.cds) {
          for (var doca in this.cds[cd].docas) {
            for (var transp in this.cds[cd].docas[doca].transpSelect) {
              this.listTransp.push({
                nome: this.cds[cd].docas[doca].transpSelect[transp],
                img: '',
                docas: [],
                docasSelected: [],
                canais: {
                  nome: [],
                  img: [],
                },
                canaisSelected: [],
                cds: [],
              })
            }
          }
          for (var canal in this.cds[cd].canais) {
            for (var transp in this.cds[cd].canais[canal].transpSelect) {
              this.listTransp.push({
                nome: this.cds[cd].canais[canal].transpSelect[transp],
                img: '',
                docas: [],
                docasSelected: [],
                canais: {
                  nome: [],
                  img: [],
                },
                canaisSelected: [],
                cds: [],
              })
            }
          }
        }
        this.listTransp = this.listTransp.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
      }

      /** Se o usuário tiver selecionado 'Nome', 'Serviço' ou 'CNPJ', irá trazer apenas a 
      transportadora que ele selecionou */
      if (this.radioOption.length == 0 && (this.selectedOption.find((element) => element == 'Nome') || this.selectedOption.find((element) => element == 'Serviço') || this.selectedOption.find((element) => element == 'CNPJ'))) {
        this.stepReplicManual = 2;
        for (var cd in this.cds) {
          for (var doca in this.cds[cd].docas) {
            for (var transp in this.cds[cd].docas[doca].transpSelect) {
              if (this.cds[cd].docas[doca].transpSelect[transp] == this.transpNome) {
                this.listTransp.push({
                  nome: this.cds[cd].docas[doca].transpSelect[transp],
                  img: '',
                  docas: [],
                  docasSelected: [],
                  canais: {
                    nome: [],
                    img: [],
                  },
                  canaisSelected: [],
                  cds: [],
                })
              }
            }
          }
          for (var canal in this.cds[cd].canais) {
            for (var transp in this.cds[cd].canais[canal].transpSelect) {
              if (this.cds[cd].canais[canal].transpSelect[transp] == this.transpNome) {
                this.listTransp.push({
                  nome: this.cds[cd].canais[canal].transpSelect[transp],
                  img: '',
                  docas: [],
                  docasSelected: [],
                  canais: {
                    nome: [],
                    img: [],
                  },
                  canaisSelected: [],
                  cds: [],
                })
              }
            }
          }
        }
        this.listTransp = this.listTransp.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
      }
      
      // Esse laço vincula os centros de distribuição, docas e canais respectivos a cada transportadora
      for (var transp in this.listTransp) {
        for (var cd in this.cds) {
          for (var doca in this.cds[cd].docas) {
            for (var transp2 in this.cds[cd].docas[doca].transpSelect) {
              if (this.cds[cd].docas[doca].transpSelect[transp2] == this.listTransp[transp].nome) {
                this.listTransp[transp].cds.push({
                  nome: this.cds[cd].nome,
                  docas: [],
                  canais: [],
                });
                this.listTransp[transp].cds.sort();
                for (var cd2 in this.listTransp[transp].cds) {
                  if (this.listTransp[transp].cds[cd2].nome == this.cds[cd].nome) {
                    this.listTransp[transp].cds[cd2].docas.push(this.cds[cd].docas[doca].nome);
                  }
                }
                this.listTransp[transp].cds = this.listTransp[transp].cds.filter((value, index, self) =>
                  index === self.findIndex((t) => (
                    t.nome === value.nome
                  ))
                )
              }
            }
          }
          for (var canal in this.cds[cd].canais) {
            for (var transp2 in this.cds[cd].canais[canal].transpSelect) {
              if (this.cds[cd].canais[canal].transpSelect[transp2] == this.listTransp[transp].nome) {
                this.listTransp[transp].cds.push({
                  nome: this.cds[cd].nome,
                  docas: [],
                  canais: [],
                });
                this.listTransp[transp].cds.sort();
                for (var cd2 in this.listTransp[transp].cds) {
                  if (this.listTransp[transp].cds[cd2].nome == this.cds[cd].nome) {
                    this.listTransp[transp].cds[cd2].canais.push(this.cds[cd].canais[canal].nome);
                  }
                }
                this.listTransp[transp].cds = this.listTransp[transp].cds.filter((value, index, self) =>
                  index === self.findIndex((t) => (
                    t.nome === value.nome
                  ))
                )
              }
            }
          }
        }
      }
    },

    // Monta as transportadoras de acordo com a opção selecionada de replicação
    montaListas() {
      this.listCds = [];

      // Se a opção for 'Doca Atual'
      if (this.radioOption == 'option01') {
        this.listTransp = [];
        for (var cd in this.cds) {
          if (this.cds[cd].nome == this.nomeCd) {
            for (var doca in this.cds[cd].docas) {
              if (this.cds[cd].docas[doca].nome == this.docaNome) {
                for (var transp in this.cds[cd].docas[doca].transpSelect) {
                  this.listTransp.push({
                    nome: this.cds[cd].docas[doca].transpSelect[transp],
                    img: '',
                    docas: [],
                    cds: [],
                  });
                }
              }
            }
          }
        }
        this.listTransp = this.listTransp.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        console.log(this.listTransp);
      }

      // Se a opção for 'Todas as Docas'
      if (this.radioOption == 'option02') {
        this.listTransp = [];
        for (var cd in this.cds) {
          for (var doca in this.cds[cd].docas) {
            for (var transp in this.cds[cd].docas[doca].transpSelect) {
              this.listTransp.push({
                nome: this.cds[cd].docas[doca].transpSelect[transp],
                img: '',
                docas: [],
                canais: [],
                cds: [],
              })
            }
          }
        }
        this.listTransp = this.listTransp.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        for (var transp in this.listTransp) {
          for (var cd in this.cds) {
            for (var doca in this.cds[cd].docas) {
              for (var transp2 in this.cds[cd].docas[doca].transpSelect) {
                if (this.cds[cd].docas[doca].transpSelect[transp2] == this.listTransp[transp].nome) {
                  if (!this.listTransp[transp].cds.includes(this.cds[cd].nome)) {
                    this.listTransp[transp].cds.push({
                      nome: this.cds[cd].nome,
                      docas: [],
                      canais: [],
                    })
                    this.listTransp[transp].cds.sort();
                  }
                  for (var cd2 in this.listTransp[transp].cds) {
                    if (this.listTransp[transp].cds[cd2].nome == this.cds[cd].nome) {
                      this.listTransp[transp].cds[cd2].docas.push(this.cds[cd].docas[doca].nome);
                      console.log(this.listTransp);
                    }
                  }
                  this.listTransp[transp].cds = this.listTransp[transp].cds.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.nome === value.nome
                    ))
                  )
                }
              }
            }
          }
        }
      }

      // Se a opção for 'Todos os Canais de Venda'
      if (this.radioOption == 'option03') {
        this.listTransp = [];
        for (var cd in this.cds) {
          for (var canal in this.cds[cd].canais) {
            for (var transp in this.cds[cd].canais[canal].transpSelect) {
              this.listTransp.push({
                nome: this.cds[cd].canais[canal].transpSelect[transp],
                img: '',
                docas: [],
                canais: [],
                cds: [],
              })
            }
          }
        }
        this.listTransp = this.listTransp.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        for (var transp in this.listTransp) {
          for (var cd in this.cds) {
            for (var canal in this.cds[cd].canais) {
              for (var transp2 in this.cds[cd].canais[canal].transpSelect) {
                if (this.cds[cd].canais[canal].transpSelect[transp2] == this.listTransp[transp].nome) {
                  this.listTransp[transp].cds.push({
                    nome: this.cds[cd].nome,
                    docas: [],
                    canais: [],
                  });
                  this.listTransp[transp].cds.sort();
                  for (var cd2 in this.listTransp[transp].cds) {
                    if (this.listTransp[transp].cds[cd2].nome == this.cds[cd].nome) {
                      this.listTransp[transp].cds[cd2].canais.push(this.cds[cd].canais[canal].nome);
                      console.log(this.listTransp);
                    }
                  }
                  this.listTransp[transp].cds = this.listTransp[transp].cds.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.nome === value.nome
                    ))
                  )
                }
              }
            }
          }
        }
      }

      // Se a opção for 'Todos os Canais de Venda e Docas'
      if (this.radioOption == 'option04') {
        this.listTransp = [];
        for (var cd in this.cds) {
          for (var doca in this.cds[cd].docas) {
            for (var transp in this.cds[cd].docas[doca].transpSelect) {
              this.listTransp.push({
                nome: this.cds[cd].docas[doca].transpSelect[transp],
                img: '',
                docas: [],
                cds: [],
              })
            }
          }
          for (var canal in this.cds[cd].canais) {
            for (var transp in this.cds[cd].canais[canal].transpSelect) {
              this.listTransp.push({
                nome: this.cds[cd].canais[canal].transpSelect[transp],
                img: '',
                canais: [],
                cds: [],
              })
            }
          }
        }
        this.listTransp = this.listTransp.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        for (var transp in this.listTransp) {
          for (var cd in this.cds) {
            for (var doca in this.cds[cd].docas) {
              for (var transp2 in this.cds[cd].docas[doca].transpSelect) {
                if (this.cds[cd].docas[doca].transpSelect[transp2] == this.listTransp[transp].nome) {
                  if (!this.listTransp[transp].cds.includes(this.cds[cd].nome)) {
                    this.listTransp[transp].cds.push({
                      nome: this.cds[cd].nome,
                      docas: [],
                      canais: [],
                    })
                    this.listTransp[transp].cds.sort();
                  }
                  for (var cd2 in this.listTransp[transp].cds) {
                    if (this.listTransp[transp].cds[cd2].nome == this.cds[cd].nome) {
                      this.listTransp[transp].cds[cd2].docas.push(this.cds[cd].docas[doca].nome);
                      console.log(this.listTransp);
                    }
                  }
                  this.listTransp[transp].cds = this.listTransp[transp].cds.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.nome === value.nome
                    ))
                  )
                }
              }
            }
            for (var canal in this.cds[cd].canais) {
              for (var transp2 in this.cds[cd].canais[canal].transpSelect) {
                if (this.cds[cd].canais[canal].transpSelect[transp2] == this.listTransp[transp].nome) {
                  this.listTransp[transp].cds.push({
                    nome: this.cds[cd].nome,
                    docas: [],
                    canais: [],
                  });
                  this.listTransp[transp].cds.sort();
                  for (var cd2 in this.listTransp[transp].cds) {
                    if (this.listTransp[transp].cds[cd2].nome == this.cds[cd].nome) {
                      this.listTransp[transp].cds[cd2].canais.push(this.cds[cd].canais[canal].nome);
                      console.log(this.listTransp);
                    }
                  }
                  this.listTransp[transp].cds = this.listTransp[transp].cds.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.nome === value.nome
                    ))
                  )
                }
              }
            }
          }
        }
      }

      // Se a opção for 'Todos as Docas' em uma transportadora específica
      if (this.radioOption == 'option05') {
        this.listTransp = [];
        for (var cd in this.cds) {
          for (var doca in this.cds[cd].docas) {
            for (var transp in this.cds[cd].docas[doca].transpSelect) {
              if (this.cds[cd].docas[doca].transpSelect[transp] == this.transpNome) {
                this.listTransp.push({
                  nome: this.cds[cd].docas[doca].transpSelect[transp],
                  img: '',
                  docas: [],
                  canais: [],
                  cds: [],
                })
              }
            }
          }
        }
        this.listTransp = this.listTransp.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        for (var transp in this.listTransp) {
          for (var cd in this.cds) {
            for (var doca in this.cds[cd].docas) {
              for (var transp2 in this.cds[cd].docas[doca].transpSelect) {
                if (this.cds[cd].docas[doca].transpSelect[transp2] == this.listTransp[transp].nome) {
                  this.listTransp[transp].cds.push({
                    nome: this.cds[cd].nome,
                    docas: [],
                    canais: [],
                  });
                  this.listTransp[transp].cds.sort();
                  for (var cd2 in this.listTransp[transp].cds) {
                    if (this.listTransp[transp].cds[cd2].nome == this.cds[cd].nome) {
                      this.listTransp[transp].cds[cd2].docas.push(this.cds[cd].docas[doca].nome);
                    }
                  }
                  this.listTransp[transp].cds = this.listTransp[transp].cds.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.nome === value.nome
                    ))
                  )
                }
              }
            }
          }
        }
      }

      // Se a opção for 'Todos os Canais de Venda' em uma transportadora específica
      if (this.radioOption == 'option06') {
        this.listTransp = [];
        for (var cd in this.cds) {
          for (var canal in this.cds[cd].canais) {
            for (var transp in this.cds[cd].canais[canal].transpSelect) {
              if (this.cds[cd].canais[canal].transpSelect[transp] == this.transpNome) {
                this.listTransp.push({
                  nome: this.cds[cd].canais[canal].transpSelect[transp],
                  img: '',
                  docas: [],
                  canais: [],
                  cds: [],
                })
              }
            }
          }
        }
        this.listTransp = this.listTransp.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        for (var transp in this.listTransp) {
          for (var cd in this.cds) {
            for (var canal in this.cds[cd].canais) {
              for (var transp2 in this.cds[cd].canais[canal].transpSelect) {
                if (this.cds[cd].canais[canal].transpSelect[transp2] == this.listTransp[transp].nome) {
                  this.listTransp[transp].cds.push({
                    nome: this.cds[cd].nome,
                    docas: [],
                    canais: [],
                  });
                  this.listTransp[transp].cds.sort();
                  for (var cd2 in this.listTransp[transp].cds) {
                    if (this.listTransp[transp].cds[cd2].nome == this.cds[cd].nome) {
                      this.listTransp[transp].cds[cd2].canais.push(this.cds[cd].canais[canal].nome);
                    }
                  }
                  this.listTransp[transp].cds = this.listTransp[transp].cds.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.nome === value.nome
                    ))
                  )
                }
              }
            }
          }
        }
      }

      // Se a opção for 'Todos os Canais de Venda e Docas' em uma transportadora específica
      if (this.radioOption == 'option07') {
        this.listTransp = [];
        for (var cd in this.cds) {
          for (var doca in this.cds[cd].docas) {
            for (var transp in this.cds[cd].docas[doca].transpSelect) {
              if (this.cds[cd].docas[doca].transpSelect[transp] == this.transpNome) {
                this.listTransp.push({
                  nome: this.cds[cd].docas[doca].transpSelect[transp],
                  img: '',
                  docas: [],
                  cds: [],
                })
              }
            }
          }
          for (var canal in this.cds[cd].canais) {
            for (var transp in this.cds[cd].canais[canal].transpSelect) {
              if (this.cds[cd].canais[canal].transpSelect[transp] == this.transpNome) {
                this.listTransp.push({
                  nome: this.cds[cd].canais[canal].transpSelect[transp],
                  img: '',
                  canais: [],
                  cds: [],
                })
              }
            }
          }
        }
        this.listTransp = this.listTransp.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        for (var transp in this.listTransp) {
          for (var cd in this.cds) {
            for (var doca in this.cds[cd].docas) {
              for (var transp2 in this.cds[cd].docas[doca].transpSelect) {
                if (this.cds[cd].docas[doca].transpSelect[transp2] == this.listTransp[transp].nome) {
                  this.listTransp[transp].cds.push({
                    nome: this.cds[cd].nome,
                    docas: [],
                    canais: [],
                  });
                  this.listTransp[transp].cds.sort();
                  for (var cd2 in this.listTransp[transp].cds) {
                    if (this.listTransp[transp].cds[cd2].nome == this.cds[cd].nome) {
                      this.listTransp[transp].cds[cd2].docas.push(this.cds[cd].docas[doca].nome);
                    }
                  }
                  this.listTransp[transp].cds = this.listTransp[transp].cds.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.nome === value.nome
                    ))
                  )
                }
              }
            }
            for (var canal in this.cds[cd].canais) {
              for (var transp2 in this.cds[cd].canais[canal].transpSelect) {
                if (this.cds[cd].canais[canal].transpSelect[transp2] == this.listTransp[transp].nome) {
                  this.listTransp[transp].cds.push({
                    nome: this.cds[cd].nome,
                    docas: [],
                    canais: [],
                  });
                  this.listTransp[transp].cds.sort();
                  for (var cd2 in this.listTransp[transp].cds) {
                    if (this.listTransp[transp].cds[cd2].nome == this.cds[cd].nome) {
                      this.listTransp[transp].cds[cd2].canais.push(this.cds[cd].canais[canal].nome);
                    }
                  }
                  this.listTransp[transp].cds = this.listTransp[transp].cds.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.nome === value.nome
                    ))
                  )
                }
              }
            }
          }
        }
      }
    },
  }
}
</script>

<style scoped>
.active {
  background-color: #BDBDBD;
}
.container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  justify-content: space-between;
}
.cardSelectedTransp {
  background-color: #ebebeb !important;
  transition: box-shadow .3s;
  border-radius: 10px;
  height: auto;
  width: auto;
}
.cardSelectedTransp:hover {
  box-shadow: 0 0 3px black;
}
.expanStyle {
  font-weight: bold;
  background-color: #ebebeb !important;
}
.expanHeader {
  font-size: 25px !important;
}
.expanGroupDesign {
  width: 80%;
}
.cardHover {
  background-color: #ebebeb;
}
.cardStyle {
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}
.cardStyle:hover {
  box-shadow: 0 0 5px black;
}
.v-list .v-list-item--active,
.v-list .v-list-item--active .titleList {
  color: white !important;
}
.activeGroup>>>.v-list-item--active {
  background-color: #6b6969b5 !important;
}
.cardStyleImg {
  background-color: #ebebeb;
  transition: box-shadow .1s;

  &:hover {
    box-shadow: 0 0 5px black;
  }
}
.widthCard {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  padding: 6px;
}
.widthCardReplic {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 6px;
}
</style>