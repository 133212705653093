<template>
  <div style="height: 100%;">
    <!-- Modal Filtro -->
    <v-dialog v-model="modal.filtro" max-width="80%">
      <v-card outlined>
        <v-card-title class="pt-6 mb-2" style="font-weight: normal;">
          <v-icon class="mr-2">fas fa-filter</v-icon>
          Filtrar - Centros de Distribuição
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <v-text-field dense outlined prepend-icon="fas fa-building" label="Nome" type="text" autocomplete="off"
                v-model="filtros.nome" clearable></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field dense outlined prepend-icon="fas fa-key" label="CNPJ" type="number" autocomplete="off"
                v-model="filtros.cnpj" clearable></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field dense outlined prepend-icon="fas fa-map-marker-alt" label="CEP" type="number"
                autocomplete="off" v-model="filtros.cep" clearable></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center" dense width="100%">
            <v-col cols="12" sm="4" md="3">
              <v-btn type="button" color="#018656" style="color: #FFFFFF; width: 100%; height: 50px;">
                <v-icon dense class="mr-2">fas fa-filter</v-icon>
                FILTRAR
              </v-btn>
            </v-col>

            <v-col cols="12" sm="4" md="3">
              <v-btn type="button" outlined style="color: #FF5252; width: 100%; height: 50px;"
                @click="modal.filtro = false">
                <v-icon dense class="mr-2">fas fa-times</v-icon>
                FECHAR
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>

            <v-col cols="12" sm="4" md="3">
              <v-btn type="button" color="#FF5252" style="color: #FFFFFF; width: 100%; height: 50px;"
                @click="() => limparFiltros()">
                <v-icon dense class="mr-2">fas fa-broom</v-icon>
                LIMPAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Modal Filtro -->

    <!-- Início Dialog Dist -->
    <v-dialog v-model="modal.centro" max-width="900" class="overflow-hidden">
      <v-card style="overflow-y: hidden; overflow-x: hidden;">
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-card-title>Painel - {{ nomecanal }}</v-card-title><v-spacer></v-spacer>
          <v-btn class="pa-2" icon @click="modal.centro = false">
            <v-icon color="error">fas fa-times</v-icon>
          </v-btn>
        </div>

        <v-row class="mb-4 px-6 mt-0">
          <v-col cols="12" sm="6" md="4">
            <v-card class="cardMenuSelected" style="background-color: #0D47A1;" @click="montaConfig()">
              <div class="cardItem" style="height: 70%;">
                <v-icon color="white" size="100">fas fa-cog</v-icon>
              </div>
              <v-card-title class="text-h6" style="color: white;">Configurações CD</v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card @click="modal.canais = true" class="cardMenuSelected" style="background-color: #FF8F00;">
              <div class="cardItem" style="height: 70%;">
                <v-icon color="white" class="pr-4" size="100">fas fa-shopping-cart</v-icon>
              </div>
              <v-card-title class="text-h6" style="color: white;">Canais de Venda</v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card @click="modal.doca = true" class="cardMenuSelected" style="background-color: #006064;">
              <div class="cardItem" style="height: 70%;">
                <v-icon color="white" size="100">fas fa-warehouse</v-icon>
              </div>
              <v-card-title class="text-h6" style="color: white;">Docas</v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Início Modal Add CD -->
    <v-dialog v-model="modal.add" max-width="900">
      <v-card>
        <v-container>
          <div style="display: flex;"><v-spacer></v-spacer>
            <v-card-title class="ml-2">Adicionar Centro de Distribuição</v-card-title><v-spacer></v-spacer>
            <v-btn icon @click="modal.add = false">
              <v-icon color="error">fas fa-times</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>

          <v-form ref="form" @submit.prevent="createCd">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pb-0">
                <v-card-text style="padding-bottom: 0">
                  <v-text-field outlined label="Nome" type="text" :rules="rulesChar" v-model="newCd.new_name">
                  </v-text-field>
                </v-card-text>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="py-0">
                <v-card-text style="padding-bottom: 0">
                  <v-text-field outlined type="number" label="CNPJ" :rules="rulesCNPJ"
                    v-model="newCd.new_cnpj">
                  </v-text-field>
                </v-card-text>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="py-0">
                <v-card-text style="padding-bottom: 0px;">
                  <v-text-field outlined type="text" counter="8" :rules="rulesCEP" label="CEP Origem"
                    v-model="newCd.new_cep">
                  </v-text-field>
                </v-card-text>
              </v-col>
            </v-row>

            <v-card-actions class="mt-2 justify-end">
              <v-btn class="mr-2" color="error" @click="modal.add = false">
                Cancelar
              </v-btn>
              <v-btn type="submit" class="ml-2" color="#018656" style="color: white;">
                Criar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Fim Dialog Add CD -->

    <!-- Configurações -->
    <v-dialog v-model="modal.config" max-width="1200" height="200" class="overflow-hidden">
      <v-card v-for="cd in cds" v-if="cd.nome == nomecanal" style="overflow-y: hidden;">
        <v-container style="height: 100%;">
          <div style="display: flex;">
            <v-card-title class="text-left">Configuração {{ nomecanal }}</v-card-title>
            <v-spacer></v-spacer>
            <v-btn @click="modal.config = false" icon>
              <v-icon color="error">fas fa-times</v-icon>
            </v-btn>
          </div>
          <v-container>
            <v-row style="width: 100%; position: relative;">
              <v-col cols="12" md="4">
                <div class="d-flex align-center">
                  <v-card-title>Faixa de CEPs atendidos</v-card-title><v-spacer></v-spacer>
                  <v-btn icon @click="montaConfigCep()">
                    <v-icon color="success">fas fa-plus</v-icon>
                  </v-btn>
                </div>
                <v-data-table dense v-model:page="page" :headers="grid.ceps.cabecalho" :items="cd.ceps"
                  hide-default-footer :page.sync="page" :items-per-page="itemsPerPage">
                </v-data-table>
                <div class="text-center pt-2">
                  <v-pagination dense v-model="page" :length="pageCount"></v-pagination>
                </div>
              </v-col>

              <v-col cols="12" md="3" style="position: relative; bottom: 5px;">
                <Mapa :listCds="cds" :nomeCanal="nomecanal" />
              </v-col>

              <v-col cols="1" align="end" class="d-flex align-end">
                <v-switch @click="todosEstados()" v-model="selectAllStates" label="Todos estados"
                  style="position: absolute;" class="mr-2"></v-switch>
              </v-col>
              <v-col cols="12" md="4">
                <v-card-title>CD Prioritário</v-card-title>
                <v-card-text>Selecione o Centro de Distribuição prioritário:</v-card-text>
                <v-select style="padding: 10px;" label="Selecione" :items="listCdsItems" @change="btnSave()"></v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-divider v-if="saveData || cd.ceps.length > 0"></v-divider>
          <v-card-actions class="justify-end pb-0 pt-2">
            <v-btn v-if="saveData || cd.ceps.length > 0" @click="modal.config = false" color="#018656"
              style="color: white;">Salvar</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Final Dialog Configurações -->

    <!-- Inicio View Canais -->
    <v-dialog :content-class="'overflow-hidden'" v-model="modal.canais" fullscreen transition="dialog-bottom-transition">
      <v-toolbar color="#018656" dark>
        <v-icon class="ml-2"> fas fa-warehouse </v-icon>
        <v-toolbar-title class="pl-4">Canais vinculados ao {{ nomecanal }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="modal.canais = false">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>

      <Canal :listCds="cds" :nomeCd="nomecanal" />

    </v-dialog>
    <!-- Final View Canais -->

    <!-- Inicio View Docas -->
    <v-dialog :content-class="'overflow-hidden'" v-model="modal.doca" fullscreen transition="dialog-bottom-transition">
      <v-toolbar color="#018656" dark>
        <v-icon class="ml-2"> fas fa-warehouse </v-icon>
        <v-toolbar-title class="pl-4">Docas vinculadas ao {{ nomecanal }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="modal.doca = false">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>

      <Docas :listCds="cds" :nomeCd="nomecanal" />

    </v-dialog>
    <!-- Fim View Docas -->

    <!-- Início Modal Add Faixa CEP -->
    <v-dialog v-model="modal.addCep" max-width="900">
      <v-card>
        <v-container class="pb-0">
          <v-card-title class="text-h5 justify-center">Adicionar - Faixa de CEP</v-card-title>
          <v-form ref="formCep" @submit.prevent="newCep">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-card-text class="pb-0">
                  <v-text-field outlined label="CEP Início" type="number" :rules="rulesCEP" clearable
                    counter="8" v-model="faixaCep01">
                  </v-text-field>
                </v-card-text>
              </v-col>
              <v-col cols="12" md="6">
                <v-card-text class="pb-0">
                  <v-text-field outlined label="CEP Fim" type="number" :rules="rulesCEP" clearable
                    counter="8" v-model="faixaCep02">
                  </v-text-field>
                </v-card-text>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn class="ma-2" color="error" @click="modal.addCep = false">Cancelar</v-btn>
          <v-btn type="submit" class="ma-2" color="#018656" style="color: white;">Criar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Final Modal Add Faixa de CEP -->

    <!-- Início principal -->
    <v-card flat>
      <v-toolbar dense flat>
        <v-icon>fas fa-industry</v-icon>
        <v-toolbar-title class="pl-4">Centros de Distribuição</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="configAdd(), resetValidation()" color="primary">Adicionar</v-btn>
        <v-app-bar-nav-icon @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <template v-if="cds.length == 0">
        <div class="text-center">
          <v-card-subtitle>Você não possui Centros de Distribuição cadastrados</v-card-subtitle>
        </div>
      </template>
      <template v-else-if="carregando">
        <v-row style="height: 100%;" dense align="center" justify="center">
          <v-progress-circular class="mt-4" indeterminate color="#018656"></v-progress-circular>
        </v-row>
      </template>
      <template v-else>
        <v-row class="ma-2" dense>
          <v-col v-for="(cd, index) in cds" :key="index" cols="12" sm="6" md="3" xl="2">
            <v-card @click="modal.centro = true, nomecanal = cd.nome" :ripple="false" class="cardSelected">
              <div>
                <v-card-text class="ml-0" style="font-weight: bolder;">{{ cd.nome }}</v-card-text>
              </div>
              <v-divider></v-divider>
              <div class="pt-0">
                <v-card-text class="pb-0 pt-3">
                  <b>CNPJ:</b> {{ cd.cnpj }}
                </v-card-text>
                <v-card-text class="pt-1">
                  <b>CEP Origem:</b> {{ cd.cep }}
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-card>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import Docas from "./frmDocas.vue";
import Canal from "./frmCanalVendas.vue";
import Mapa from "./mapaBrasil.vue";

export default {
  name: "CadastroCD",
  components: {
    Docas,
    Canal,
    Mapa,
  },
  data: () => ({
    page: 1,
    selectAllStates: false,
    faixaCep01: null,
    faixaCep02: null,
    itemsPerPage: 6,
    grid: {
      ceps: {
        cabecalho: [
          { text: 'Estado', value: 'estado' },
          { text: 'Faixa de CEP', value: 'faixa', align: 'center' },
        ],
        items: [],
      },
    },

    /** Lista com todos os estados e suas faixas de CEP para fazer a vinculação quando o usuário 
    clicar no mapa */
    allStates: [
      { state: 'TO', faixa: '77000-000 - 77999-999' },
      { state: 'BA', faixa: '40000-000 - 48999-999' },
      { state: 'SE', faixa: '49000-000 - 49999-999' },
      { state: 'PE', faixa: '50000-000 - 56999-999' },
      { state: 'AL', faixa: '57000-000 - 57999-999' },
      { state: 'RN', faixa: '59000-000 - 59999-999' },
      { state: 'CE', faixa: '60000-000 - 63999-999' },
      { state: 'PI', faixa: '64000-000 - 64999-999' },
      { state: 'MA', faixa: '65000-000 - 65999-999' },
      { state: 'AP', faixa: '68900-000 - 68999-999' },
      { state: 'PA', faixa: '66000-000 - 68899-999' },
      { state: 'RR', faixa: '69300-000 - 69399-999' },
      { state: 'AM', faixa: '69400-000 - 69899-999' },
      { state: 'AC', faixa: '69900-000 - 69999-999' },
      { state: 'RO', faixa: '76800-000 - 76999-999' },
      { state: 'MT', faixa: '78000-000 - 78899-999' },
      { state: 'MS', faixa: '79000-000 - 79999-999' },
      { state: 'GO', faixa: '73700-000 - 76799-999' },
      { state: 'PR', faixa: '80000-000 - 87999-999' },
      { state: 'SC', faixa: '88000-000 - 89999-999' },
      { state: 'RS', faixa: '90000-000 - 99999-999' },
      { state: 'SP', faixa: '01000-000 - 19999-999' },
      { state: 'MG', faixa: '30000-000 - 39999-999' },
      { state: 'RJ', faixa: '20000-000 - 28999-999' },
      { state: 'ES', faixa: '29000-000 - 29999-999' },
      { state: 'DF', faixa: '73000-000 - 73699-999' },
      { state: 'PB', faixa: '58000-000 - 58999-999' },
    ],
    nomecanal: "",
    saveData: false,
    codigo_empresa: Number(window.localStorage.getItem("factory")),
    cds: [],

    // Cria validações para criação de Centro de Distribuição
    rulesChar: [
      value => {
        if (value) return true

        return 'Este campo é obrigatório.'
      }
    ],
    rulesCNPJ: [
      value => {
        if (!value) return 'Este campo é obrigatório.'

        if (value.length < 14) return 'Insira um CNPJ válido.'

        return true
      }
    ],
    rulesCEP: [
      value => {
        if (!value) return 'Este campo é obrigatório.'

        if (value.length != 8) return 'Insira um CEP válido.'

        return true
      }
    ],
    /* --------------------------------------------------------- */
    modal: {
      filtro: false,
      add: false,
      addCep: false,
      centro: false,
      config: false,
      doca: false,
      addDoca: false,
      canais: false,
    },
    newCd: {
      new_name: "",
      new_cnpj: "",
      new_cep: "",
    },
    filtros: {
      nome: null,
      cnpj: null,
      cep: null,
    },
    listCdsItems: [],
    carregando: false,
  }),
  computed: {
    // Cálculo paginação da tabela CEPs
    pageCount(){
      for(var cd in this.cds){
        if(this.cds[cd].nome == this.nomecanal){
          console.log(Math.ceil(this.cds[cd].ceps.length / this.itemsPerPage));
          return Math.ceil(this.cds[cd].ceps.length / this.itemsPerPage)
        }
      }
    },
  },
  created(){
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  mounted(){
    this.carregarCds();
  },
  methods:{
    // Quando clica no botão 'Adicionar' no Centro de Distribuição, abre a tela e reseta os campos
    configAdd() {
      this.modal.add = true;
      this.newCd.new_name = null;
      this.newCd.new_cnpj = null;
      this.newCd.new_cep = null;
    },
    resetValidation(){
      this.$refs.form.resetValidation()
    },
    btnSave(){
      this.saveData = true;
    },

    // Quando o usuário clica no botão 'Configurações CD' no painel Centro de Distribuição
    montaConfig(){
      this.listCdsItems = [];
      this.modal.config = true;
      this.saveData = false;
      for(var cd in this.cds){
        if(this.cds[cd].nome != this.nomecanal){
          this.listCdsItems.push(this.cds[cd].nome);
        }
        if(this.cds[cd].nome == this.nomecanal){
          if(Object.values(this.cds[cd].ceps).length < 27){
            this.selectAllStates = false;
          }else{
            this.selectAllStates = true;
          }
        }
      }
    },
    limparFiltros(){
      this.filtros.nome = ''
      this.filtros.cnpj = ''
      this.filtros.cep = ''
    },
    fechar() {
      this.$router.push("/");
    },

    // Função para criar Centro de Distribuição
    createCd() {
      this.$refs.form.validate();
      if(this.$refs.form.validate() == true){
        this.cds.push({
          nome: this.newCd.new_name,
          cnpj: this.newCd.new_cnpj,
          cep: this.newCd.new_cep,
          canais: [],
          docas: [],
          ceps: [],
          cepsList: [],
        })
        this.modal.add = false;
        this.carregarCds();
      }
    },
    async carregarCds(){
      this.carregando = true;

      setTimeout(() => (this.carregando = false), 1000);
    },

    // Botão para selecionar todos os estados em 'Configurações CD'
    todosEstados(){
      for(var cd in this.cds){
        if(this.cds[cd].nome == this.nomecanal){
          if(this.selectAllStates == true){
            for(var cep in this.allStates){
              this.cds[cd].ceps.push({
                estado: this.allStates[cep].state,
                faixa: this.allStates[cep].faixa,
              });
              this.cds[cd].ceps = this.cds[cd].ceps.filter((value, index, self) =>
                index === self.findIndex((t) => (
                  t.estado === value.estado
                ))
              )
              this.cds[cd].cepsList.push(this.allStates[cep].state);
            }
          }else{
            this.cds[cd].ceps = [];
            this.cds[cd].cepsList = [];
          }
        }
      }
    },

    // Ao clicar no ícone '+' na tela de Configuração do Centro de Distribuição
    montaConfigCep(){
      this.modal.addCep = true;
      this.faixaCep01 = null;
      this.faixaCep02 = null;
      this.resetValidationCep();
    },
    resetValidationCep(){
      this.$refs.formCep.resetValidation()
    },
    newCep(){
      console.log(this.$refs.formCep.validate());
      this.$refs.formCep.validate();
      if(this.$refs.formCep.validate() == true){
        this.modal.addCep = false;
      }
    },
  },
}
</script>

<style scoped>
.cardItem {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardSelected {
  top: 0px;
  position: relative;
  background-color: #ebebeb;
  border-radius: 4px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #f2f8f9;
  height: 160px;

  &:hover {
    border: 1px solid #9E9E9E;
  }
}
.cardMenuSelected {
  height: 318px;
  transition: box-shadow .3s;
  border-radius: 10px;
  cursor: pointer;
}
.cardMenuSelected:hover {
  box-shadow: 0 0 8px #212121;
}
</style>